export const environment = {
  production: false,
  apiUrl: 'https://exp-api.getcamber.com/exp-multiplexer-cp-qa-launch2020/proterra/controlpanel/v1/',
  region: 'us-east-1',
  identityPoolId: '',
  userPoolId: 'us-east-1_amQD5WlEB',
  clientId: '6jqcgrkdr0bnif02vuihfdohgd',
  cognito_idp_endpoint: 'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_amQD5WlEB',
  cognito_identity_endpoint: '',
  sts_endpoint: ''
};
